import React from 'react'
import img1 from "../Images/1.svg"
import img2 from "../Images/as3.jpg"

export default function Carousel() {
  return (
//     <div id="carouselExampleSlidesOnly" className="carousel slide bg-white mt-4" data-ride="carousel">
//       <div className="carousel-inner">
//       <div className="carousel-item active d-flex justify-content-center align-items-center">
//   <div>
//     <img className="d-block " src={img2} alt="First slide" />
//     <marquee><span className='text-white bg-primary mt-2'>Navigate Your Destiny with Suvico Solutions - Where Stars Align for Your Success.</span></marquee>
//   </div>
// </div>

       

//       </div>

//     </div>

<div class="carousel_home">
  <div class="logos_home"></div>
  <div class="mask_home"></div>
</div>
  )
}
